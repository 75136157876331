<template>
  <router-view v-if="!permissionDenied" />
  <PermissionDeniedPanel v-else />
</template>

<i18n>
{
  "nl": {
    "title": "Sporten bij de Raad van Beheer"
  },
  "en": {
    "title": "Sports at Raad van Beheer"
  }
}
</i18n>
<script>

import { useI18n } from 'vue-i18n';
import { provide } from 'vue';
import { useHead } from '@unhead/vue';
import useSession from './session/session.js';
import PermissionDeniedPanel from './components/users/PermissionDeniedPanel.vue';

export default {
  name: 'Application',
  components: { PermissionDeniedPanel },
  setup() {
    const { t } = useI18n();

    useHead({
      titleTemplate: (title) => {
        if (title === undefined) {
          // Do not update if we do not have a title set
          return null;
        }
        return ((title) ? `${title} – ${t('title')}` : t('title'));
      },
    });

    const { permissionDenied } = useSession();

    provide('windowed', false);

    return {
      t,

      permissionDenied,
    };
  },
};
</script>
